import axios from 'axios';

class OrderService {
    createOrder(order) {
        return axios.post('/orders',{ order: order })
                .then(response => {
                  return response
                })
      }
}

export default new OrderService();
<template>
  <div>
    <!-- <div class="config-shopping">
      <div class="d-flex mb-2">
        <label class="delivery-label" for=""
          >เลือกครัว<span class="red--text">*</span>:
        </label>
        <select v-model="kitchen_type" class="selectActive">
          <option
            v-for="(item, index) in itemsKitchen"
            :key="index"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
      </div>
      <div class="d-flex">
        <label class="delivery-label" for=""
          >วันที่และเวลารับของ<span class="red--text">*</span>:
        </label>
        <input format="DD-MM-YYYY HH:mm" type="datetime-local" class="mt-1 selectActive" v-model="dateTimeReceive" placeholder="ระบุวันที่และเวลาในการรับของ">
      </div>
    </div> -->

    <div class="bottom-bar">
      <div class="wrapper-bar">
        <div class="mr-2 wrapper-info">
          <span>{{ this.$store.getters.getShoppingCart.length }} รายการ</span>
          <h4 class="payment-no-vat">
            ยอดชำระไม่รวมVAT ฿{{
              this.$store.getters.getShoppingCart.reduce(
                (acc, curr) => acc + curr.summary,
                0
              ) | currency2
            }}
          </h4>
          <h2>
            ยอดชำระทั้งหมด ฿{{
              this.$store.getters.getShoppingCart.reduce(
                (acc, curr) => acc + curr.summaryVat,0) | currency2
            }}
          </h2>
        </div>
        <div class="d-flex justify-end" @click.prevent="submitOrder">
          <v-btn :loading="loadSubmitOrder" class="btn-buynow">ยืนยันการสั่งซื้อ</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import Swal from "sweetalert2";
import OrderService from "@/services/order.service"
import OrderSupplierService from "@/services/orderSupplier.service"
export default {
  name: "BottomBar",
  data() {
    return {
      line_uid: "",
      loadSubmitOrder: false,
      dateTimeReceive: null,
      itemsKitchen: ["Main Kitchen", "Coffee Shop", "HUB", "HYD"],
      kitchen_type: "Main Kitchen",
    };
  },
  async created() {
    await liff.init(
      { liffId: "1656130154-dRLb8BGe" },
      () => {
        if (!liff.isLoggedIn()) {
          liff.login({
            redirectUri: "https://rinen-pos-fastway.web.app/cart",
          });
        } else {
          this.liffGetProfile();
        }
      },
      (err) => console.log(err.code, err.message)
    );
  },
  methods: {
    liffGetProfile() {
      liff
        .getProfile()
        .then((profile) => {
          this.line_uid = profile.userId;
          console.log(this.line_uid);
        })
        .catch((err) => console.log(err));
    },
    submitOrder() {
      console.log(this.dateTimeReceive);
      if ( this.$store.getters.getShoppingCart.length > 0 ) {
        Swal.fire({
          title: "ยืนยันคำสั่งซื้อ?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.loadSubmitOrder = true
            let supplierIds = [
              ...new Set(
                this.$store.getters.getShoppingCart.map(
                  (item) => item.supplierID
                )
              ),
            ];
            let order = {
              suppliers: [],
              total_amount: 0,
              totalAmountVat: 0,
              kitchenType: "Main Kitchen",
              deliveryDate: null,
              // deliveryDate: this.dateTimeReceive.toUTCString(),
              createBy: this.line_uid,
              // createBy: 'U690e93ea9c5bdc0d105896b8b8f0da47'
            };
            for (let index = 0; index < supplierIds.length; index++) {
              let supplier = {
                id: supplierIds[index],
                goods: [],
              };
              let cartGoods = this.$store.getters.getShoppingCart.filter(
                (cart) => cart.supplierID == supplierIds[index]
              );
              for (let index2 = 0; index2 < cartGoods.length; index2++) {
                let good = {
                  id: cartGoods[index2].id,
                  quantity: parseFloat(cartGoods[index2].quantity),
                  unitPrice: cartGoods[index2].unitPrice,
                  amount: cartGoods[index2].summary,
                  note: cartGoods[index2].note,
                  vat: cartGoods[index2].vat,
                };
                order.total_amount += cartGoods[index2].summary;
                order.totalAmountVat += cartGoods[index2].summaryVat;
                supplier.goods.push(good);
              }
              order.suppliers.push(supplier);
            }
            console.log(order);
            OrderService.createOrder(order)
              .then((res) => {
                console.log(res);
                // const data = this.$store.getters.getShoppingCart
                const keys_to_keep = ['id', 'quantity'];
                const goods = this.$store.getters.getShoppingCart.map(data => keys_to_keep.reduce((acc, curr) => {
                  acc[curr] = data[curr];
                  return acc;
                }, {}));
                OrderSupplierService.approveOrderPR(res.data.purchaseRequisition[0].id, {goods})
                  .then(async () => {
                    this.$store.dispatch("clearItemShoppingCart");
                    await Swal.fire({
                      icon: "success",
                      title: "สั่งซื้อสำเร็จ",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                    this.loadSubmitOrder = false
                    liff.closeWindow();
                  }).catch((error) => {
                    this.loadSubmitOrder = false
                    Swal.fire({
                      icon: "error",
                      title: `${error}`,
                      showConfirmButton: true,
                    });
                  })
              })
              .catch((err) => {
                this.loadSubmitOrder = false
                Swal.fire({
                  icon: "error",
                  title: `${err}`,
                  showConfirmButton: true,
                });
              });
          }
        });
      } else {
        let title = "";
        if (this.$store.getters.getShoppingCart.length == 0) {
          title = "ไม่มีสินค้าในรถเข็น";
        }
        Swal.fire({
          icon: "error",
          title: `${title}`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
.config-shopping {
  position: fixed;
  width: 100%;
  bottom: 70px;
  background: #fff;
  padding: 10px;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
}
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px !important;
  background: #fff;
  z-index: 1;
  box-shadow: none;
}
.wrapper-bar {
  height: 70px;
  width: 100%;
  display: flex !important;
  justify-content: flex-end !important;
}
.btn-buynow {
  width: 120px;
  border-radius: 0px;
  height: 70px !important;
  background: #e53935 !important;
  color: #fff;
}
.wrapper-info {
  text-align: right;
  line-height: 20px;
}
.wrapper-info span,
.payment-no-vat {
  font-size: 12px;
  color: rgb(59, 59, 59);
}
.wrapper-info h2 {
  color: rgb(248, 0, 0);
  font-size: 18px;
}
.delivery-label {
  white-space: nowrap;
  margin-right: 4px;
  font-size: 14px;
}
.selectActive {
  width: 100% !important;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  color: #757575;
  padding: 5px;
  appearance: menulist !important;
  font-size: 14px;
}
</style>
<template>
  <div class="cart">
    <v-app>
      <v-container fluid class="pb-16">
        <v-card class="wrapper-card-cart pt-8 mt-6">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <v-btn
                  icon
                  small
                  dark
                  class="mr-2 btn-prev"
                  @click.prevent="$router.back()"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <h1>รถเข็น</h1>
              </div>
            </div>
          </v-app-bar>
          <v-container fluid>
            <div class="no-items-cart" v-if="this.$store.getters.getShoppingCart.length < 1">
              <h1>ไม่มีสินค้าอยู่ในรถเข็น</h1>
              <v-btn @click.prevent="$router.push('/shopping-goods')" color="primary" class="mt-4">
                ซื้อสินค้า
              </v-btn>
            </div>
            <div
              v-for="(item, i) in this.$store.getters.getShoppingCart"
              :key="i"
            >
              <div class="wrapper-list-menu">
                <div>
                  <img width="120px" height="100px" :src="item.imagePath" />
                </div>
                <div class="ml-3 pt-1 wrapper-menu-all">
                  <h2>{{ item.name }}</h2>
                  <div class="wrapper-menu-sub">
                    <h4>{{ item.unitPrice }} บาท/{{ item.unit }} (VAT {{item.vat}}%)</h4>
                    <div class="d-flex justify-space-between">
                      <div class="d-flex align-center">
                        <v-btn icon>
                          <v-icon
                            color="primary"
                            @click.prevent="decItemQuantity(item.id, item.unitPrice, item.vat)"
                          >
                            mdi-minus-circle-outline
                          </v-icon>
                        </v-btn>
                        <input
                          @change="editItemQuantity(item.id, item.quantity, item.unitPrice, item.vat)"
                          class="input-value-quantity"
                          type="text"
                          v-model="item.quantity"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                        <v-btn icon @click.prevent="addItemQuantity(item.id,item.unitPrice, item.vat)">
                          <v-icon color="primary">mdi-plus-circle</v-icon>
                        </v-btn>
                      </div>
                      <div>
                        <v-btn icon @click.prevent="delItemQuantity(item.id)">
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div class="text-right mr-3">
                      รวมเป็นเงิน {{(item.unitPrice * item.quantity) + ((item.unitPrice * item.quantity) * (item.vat / 100)) | currency2}} บาท
                    </div>
                  </div>
                </div>
              </div>
              <div class="remark">
                <label for="remark">หมายเหตุ <span style="color: #444; font-size: 12px">(ไม่จำเป็นต้องกรอก)</span></label>
              <v-textarea
                outlined
                dense
                class="mt-1"
                rows="2"
                hide-details
                v-model="$store.getters.getShoppingCart[i].note"
                @change="addRemarkGoods(i,$store.getters.getShoppingCart[i].note)"
                id="remark"
              ></v-textarea>
              </div>
              <v-divider class="mb-2 mt-3"></v-divider>
            </div>
          </v-container>
        </v-card>
      </v-container>
      <!-- <div class="delivery-date">
        <label for="">วันที่รับของ (ระบุวันที่และเวลา)<span class="red--text">*</span> </label>
        <date-picker format="DD-MM-YYYY HH:mm" class="mt-1" style="width: 100%;" v-model="dateTimeReceive" type="datetime" placeholder="ระบุวันที่และเวลาในการรับของ"></date-picker>
      </div> -->
      <BottomBarPayment />
    </v-app>
  </div>
</template>

<script>
import HeaderTitlePage from "@/components/HeaderTitlePage.vue";
import BottomBarPayment from "@/components/BottomBarPayment.vue";
import Swal from "sweetalert2";
// import liff from '@line/liff'
import DatePicker from 'vue2-datepicker';

export default {
  name: "cart",
  components: {
    DatePicker
  },
  data() {
    return {
      itemsAllCart: [],
      remark: ''
    };
  },
  components: {
    HeaderTitlePage,
    BottomBarPayment,
  },
  methods: {
    addRemarkGoods(index,input) {
      this.$store.dispatch("addNoteGoods", {index,input})
    },
    editItemQuantity(id, quan, price, vat) {
      if (quan == 0) {
        quan = 0.1
      } 
      let quantity = parseFloat(quan);
      this.$store.dispatch("editItemQuantity", { id, quantity, price, vat });
    },
    addItemQuantity(id, price, vat) {
      this.$store.dispatch("addItemQuantity", { id, price, vat});
    },
    decItemQuantity(id, price, vat) {
      this.$store.dispatch("decItemQuantity", { id, price, vat });
    },
    delItemQuantity(id) {
      Swal.fire({
        title: "คุณต้องการลบ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("delItemQuantity", id);
        }
      });
    },
  },
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}

.wrapper-list-menu {
  display: grid;
  grid-template-columns: 120px 1fr;
}
.wrapper-menu-sub h4, .wrapper-menu-sub span {
  font-weight: 400;
  font-size: 15px;
}
.wrapper-menu-all {
  width: 99%;
  overflow: hidden;
}
.wrapper-menu-all h2 {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input-value-quantity {
  width: 40px;
  text-align: center;
  border: 2px solid #3280d5;
  border-radius: 4px;
  color: #3280d5;
  font-weight: 600;
  outline: none;
}
.no-items-cart {
  color: rgb(238, 15, 15);
  text-align: center;
  margin: 2rem 0;
  display: block;
}
.no-items-cart h1 {
  font-size: 1.4rem;
}
.wrapper-card-cart {
  margin-bottom: 100px !important;
}

</style>